import React from 'react';
import injectSheet from 'react-jss';

const styles = {
    cookiePreference: {
        display: 'inline',
        '& a': {
            whiteSpace: 'nowrap'
        }
    },
    consentBar: {
        position: 'fixed',
        zIndex: '110',
        width: '100%',
        left: '0',
        bottom: '0',
        backgroundColor: '#333',
        color: '#fff',
        margin: '0 auto',
        fontSize: '14px',
        fontFamily: "'Open Sans','HelveticaNeue','HelveticaNeueu',Arial,sans-serif"
    }
};

class Consent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { consentScriptLoaded: false, analyticsScriptLoaded: false };
    }

    componentDidMount() {
        this.loadConsentScript();
    }

    componentDidUpdate() {
        this.loadAnalyticsScript();
    }

    render() {
        return <div id="consent">{this.state.consentScriptLoaded ? this.renderBanner() : ''}</div>;
    }

    renderBanner() {
        const { classes } = this.props;
        return (
            <div>
                <span id="cookie_preferences" className={classes.cookiePreference} />
                <div id="consent_blackbar" className={classes.consentBar} />
            </div>
        );
    }

    loadAnalyticsScript() {
        const script = document.createElement('script');
        const self = this;

        if (self.state.consentScriptLoaded && !self.state.analyticsScriptLoaded) {
            script.src = 'https://tags.tiqcdn.com/utag/garmin/main/prod/utag.js';
            script.async = true;
            script.type = 'text/javascript';
            script.onload = function() {
                self.setState({ analyticsScriptLoaded: true });
            };
            // copying timeout logic provided from Digital Insights team
            setTimeout(function() {
                document.head.appendChild(script);
            }, 500);
        }
    }

    loadConsentScript() {
        const script = document.createElement('script');
        const self = this;

        script.src =
            'https://consent.trustarc.com/notice?domain=connect.garmin.com&c=cookie_preferences&js=nj&noticeType=bb&gtm=1&text=true';
        script.onload = function() {
            self.setState({ consentScriptLoaded: true });
        };
        document.body.appendChild(script);
    }
}

export default injectSheet(styles)(Consent);
