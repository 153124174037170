const colors = {
    // colors in comments refer to "old" colors and mapping to current colors.
    // these comments can be deleted after completion of ADA project

    xxx: 'transparent', // undefined
    crystalbell: '#efefef', // body + footer background

    // neutrals
    black: '#000',
    grey: 'grey', // same as #808080 (not an official Garmin  color)
    white: '#fff',
    transparent: 'transparent',

    // grays (from dark to light)
    darkui_surface_1: '#101010',
    darkui_surface_2: '#1d1d1d',
    darkui_surface_3: '#252525', // #222
    darkui_surface_4: '#3b3b3b', // #333
    darkui_accent_1: '#515151',
    darkui_accent_2: '#6c6c6c', // #6c6c6c, #777777, #888888, #6e8594
    darkui_accent_3: '#a6a6a6',
    lightui_accent_2: '#c3c3c3',
    lightui_accent_1: '#d8d8d8',
    lightui_surface_4: '#e4e4e4',
    lightiu_surface_3: '#efefef',
    lightui_surface_2: '#f4f4f4',
    lightui_surface_1: '#fbfbfb',

    // transparent grays (from dark to light)
    darkui_accent_2_80: 'rgba(108, 108, 108, 0.8)', // #6c6c6c with 80% opacity
    black_65: 'rgba(0, 0, 0, 0.65)', // (non_Garmin_UX_color but used for ada text underlay)
    black_10: 'rgba(0, 0, 0, 0.1)', // #000 with 10% opacity
    lightui_surface_1_90: 'rgba(251, 251, 251, 0.9)', // #fbfbfb with 90% opacity
    white_25: 'rgba(255, 255, 255, 0.25)', // #fff with 25% opacity
    white_65: 'rgba(255, 255, 255, 0.65)', // (non_Garmin_UX_color but used for ada text underlay)
    white_85: 'rgba(255, 255, 255, 0.85)', // #fff with 85% opacity

    // blues
    blue_dark_3: '#004ba0',
    blue_dark_2: '#0a59b2',
    blue_dark_1: '#1265c2', // #1265c2 (new primary button hover)
    blue_primary: '#1976d2', // #216cc0, #0c7cad, #0d82b5, #0d87bd, #3397ed, #00a2ea, #10a8ec, #11a9ed, #13b2ea, #16b7ef, (also the following) #64aee8, #5aa8ea, #76b2e4
    blue_light_3: '#2a88e6',
    blue_light_2: '#3b97f3',
    blue_light_1: '#54a9fe',

    // transparent blues
    blue_light_1_50: 'rgba(84, 169, 254, 0.5)', // #54a9fe with 50% opacity
    blue_light_1_30: 'rgba(84, 169, 254, 0.3)', // #54a9fe with 30% opacity

    // green
    green_dark_3: '#289e3f',
    green_dark_2: '#30b147',
    green_dark_1: '#3ebe55',
    green_primary: '#4ecf64',
    green_light_3: '#68db7b',
    green_light_2: '#8cea9a',
    green_light_1: '#b0f2b9',

    // lime
    lime_dark_3: '#74b816',
    lime_dark_2: '#82c91e',
    lime_dark_1: '#94d82d',
    lime_primary: '#a9e34b',
    lime_light_3: '#c0eb75',
    lime_light_2: '#d8f5a2',
    lime_light_1: '#e9fac8',

    // teal
    teal_dark_3: '#0b7285',
    teal_dark_2: '#0c8599',
    teal_dark_1: '#1098ad',
    teal_primary: '#15aabf',
    teal_light_3: '#22b8cf',
    teal_light_2: '#3bc9db',
    teal_light_1: '#66d9e8',

    // orange
    orange_dark_3: '#df4d18',
    orange_dark_2: '#e85f15',
    orange_dark_1: '#f27019',
    orange_primary: '#ff841e',
    orange_light_3: '#ff9735',
    orange_light_2: '#feaf5a',
    orange_light_1: '#ffbf76',

    // yellow
    yellow_dark_3: '#e4a700',
    yellow_dark_2: '#edb312',
    yellow_dark_1: '#f5bf2a',
    yellow_primary: '#faca48',
    yellow_light_3: '#fdd66b',
    yellow_light_2: '#fedb7c',
    yellow_light_1: '#ffe18e',

    // violet
    violet_dark_3: '#a606bc',
    violet_dark_2: '#bf0dc3',
    violet_dark_1: '#cf18c4',
    violet_primary: '#de33cb',
    violet_light_3: '#e04cc9',
    violet_light_2: '#e069ca',
    violet_light_1: '#df88cd',

    // purple
    purple_dark_3: '#4f28c0',
    purple_dark_2: '#5a30d7',
    purple_dark_1: '#6439e4',
    purple_primary: '#6f42f3',
    purple_light_3: '#815af7',
    purple_light_2: '#9370f9',
    purple_light_1: '#aa8dfc'
};
export default colors;
