import React from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';

import themeColors from '../styles/theme';
import colors from '../styles/colors';

const colorMapping = {
    primary: {
        backgroundColor: themeColors.primaryButtonBackground,
        color: themeColors.primaryButtonText,
        focus: {
            backgroundColor: themeColors.primaryButtonBackgroundFocus
        },
        hover: {
            backgroundColor: themeColors.primaryButtonBackgroundHover
        },
        active: {
            backgroundColor: themeColors.primaryButtonBackgroundActive
        }
    },
    secondary: {
        backgroundColor: themeColors.secondaryButtonBackground,
        color: themeColors.secondaryButtonText,
        focus: {
            backgroundColor: themeColors.secondaryButtonBackgroundFocus
        },
        hover: {
            backgroundColor: themeColors.secondaryButtonBackgroundHover
        },
        active: {
            backgroundColor: themeColors.secondaryButtonBackgroundActive
        }
    },
    danger: {
        backgroundColor: themeColors.dangerButtonBackground,
        color: themeColors.dangerButtonText,
        focus: {
            backgroundColor: themeColors.dangerButtonBackgroundFocus
        },
        hover: {
            backgroundColor: themeColors.dangerButtonBackgroundHover
        },
        active: {
            backgroundColor: themeColors.dangerButtonBackgroundActive
        }
    },
    reverse: {
        backgroundColor: themeColors.reverseButtonBackground,
        color: themeColors.reverseButtonText,
        focus: {
            backgroundColor: themeColors.reverseButtonBackgroundFocus
        },
        hover: {
            backgroundColor: themeColors.reverseButtonBackgroundHover
        },
        active: {
            backgroundColor: themeColors.reverseButtonBackgroundActive
        }
    },
    disabled: {
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'none',
        opacity: '.5'
    }
};

const sizeMapping = {
    small: {
        fontSize: '11px',
        padding: '0 12px',
        height: '22px'
    },
    large: {
        height: '44px',
        padding: '0 25px',
        fontSize: '16px'
    },
    medium: {
        height: '33px',
        padding: '0 20px',
        fontSize: '14px'
    }
};

const styles = {
    button: {
        background: 'none',
        margin: 0,
        cursor: 'pointer',
        borderRadius: '4px',
        border: 'none',
        transition: 'background-color 200ms',
        backgroundColor: (props) =>
            props.reverse ? colorMapping.reverse.backgroundColor : colorMapping[props.color].backgroundColor,
        color: (props) => (props.reverse ? colorMapping.reverse.color : colorMapping[props.color].color),
        fontSize: (props) => sizeMapping[props.size].fontSize,
        height: (props) => sizeMapping[props.size].height,
        padding: (props) => sizeMapping[props.size].padding,
        '&:focus': {
            backgroundColor: (props) =>
                props.reverse
                    ? colorMapping.reverse.focus.backgroundColor
                    : colorMapping[props.color].focus.backgroundColor,
            outline: 'none',
            boxShadow: '0 0 0 2px ' + colors.white + ', 0 0 0 4px ' + themeColors.primary
        },
        '&:hover': {
            backgroundColor: (props) =>
                props.reverse
                    ? colorMapping.reverse.hover.backgroundColor
                    : colorMapping[props.color].hover.backgroundColor
        },
        '&:active': {
            backgroundColor: (props) =>
                props.reverse
                    ? colorMapping.reverse.active.backgroundColor
                    : colorMapping[props.color].active.backgroundColor
        },
        '&[disabled]': {
            backgroundColor: (props) =>
                props.reverse ? colorMapping.reverse.backgroundColor : colorMapping[props.color].backgroundColor,
            boxShadow: 'none',
            opacity: '0.5',
            cursor: 'not-allowed',
            pointerEvents: 'none'
        }
    }
};

class Button extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if (!this.props.onClick) {
            return;
        }
        this.props.onClick();
    }

    render() {
        const { classes } = this.props;
        return (
            <button className={classes.button} disabled={this.props.disabled} onClick={this.onClick} type="button">
                {this.props.children}
            </button>
        );
    }
}

Button.propTypes = {
    children: PropTypes.node,
    color: PropTypes.oneOf(['primary', 'secondary', 'danger', 'disabled']),
    size: PropTypes.oneOf(['large', 'medium', 'small']),
    /** Button without frame */
    reverse: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
};

Button.defaultProps = {
    color: 'primary',
    size: 'medium'
};

export default injectSheet(styles)(Button);
