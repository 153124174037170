import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import injectSheet from 'react-jss';

import Header from './header';
import Footer from './footer';
import i18next from '../config/i18next';

import facebookSVG from '../data/images/SVG-files/social-facebook-icon.svg';
import instaSVG from '../data/images/SVG-files/social-instagram-icon.svg';
import twitterSVG from '../data/images/SVG-files/social-twitter-icon.svg';

const styles = {
    layout: {
        // The expression: typeof window !== `undefined` prevents Gatsby from loading the background image at build time when it's creating the static HTML, bg image selection is done later at runtime
        backgroundImage: (props) =>
            props.backgroundUrl && typeof window !== `undefined` ? `url(${props.backgroundUrl})` : 'none',
        backgroundColor: (props) => `${props.backgroundColor}`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: (props) => (props.noMinHeight ? null : '800px'),
        overflowX: 'auto',
        height: (props) => (props.backgroundUrl ? 'calc(100vh - 50px)' : '100%')
    },
    responsiveLayout: {
        background: '#333333',
        '& + div': {
            display: 'none'
        }
    },
    '@media only screen and (max-width: 1250px)': {
        layout: {
            backgroundImage: (props) => {
                // This makes the tablet size backgrounds optional
                if (props.tabletBackgroundUrl.length > 0 && typeof window !== `undefined`) {
                    return `url(${props.tabletBackgroundUrl})`;
                } else if (typeof window !== `undefined`) {
                    return `url(${props.responsiveBackgroundUrl})`;
                }
            },
            backgroundPosition: '50% 50%'
        }
    },
    '@media only screen and (max-width: 767px)': {
        layout: {
            backgroundImage: (props) => {
                if (typeof window !== `undefined`) {
                    return `url(${props.responsiveBackgroundUrl})`;
                }
            },
            backgroundPosition: '50% 100%'
        }
    }
};

class Layout extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            backgroundColor: '',
            switchToResponsiveMode: false
        };
        this.toggleResponsiveMode = this.toggleResponsiveMode.bind(this);
    }

    /**
     * toggleResponsiveMode - occurs when in mobile mode and the navigation menu is rendered
     *
     * @param  {boolean} toSwitch whether or not to change the layout to responsive mode
     * @return {undefined}
     */
    toggleResponsiveMode(toSwitch) {
        this.setState({
            switchToResponsiveMode: toSwitch
        });
    }

    render() {
        const { classes, children, appendChildren, showActivityTracking } = this.props;
        const { switchToResponsiveMode } = this.state;
        let background = switchToResponsiveMode ? classes.responsiveLayout : classes.layout;
        const renderFooter = switchToResponsiveMode ? (
            ''
        ) : (
            <Footer
                instaIcon={instaSVG}
                facebookIcon={facebookSVG}
                twitterIcon={twitterSVG}
                showActivityTracking={showActivityTracking}
            />
        ); // The footer will not be rendered when in mobile mode AND user clicks to show navigation menu
        const renderChildren = switchToResponsiveMode ? '' : children; // Any content will not be rendered when in mobile mode AND user clicks to show navigation menu

        return (
            <>
                <Helmet>
                    {/* <!-- GLOBAL METADATA --> */}
                    <html lang={i18next.language} />
                    <meta name="robots" content="index,follow" />
                    <meta name="revisit-after" content="15 days" />
                    <meta name="author" content="Garmin International" />
                    <link rel="shortcut icon" href="https://static.garmincdn.com/favicon.ico" />
                    {/* <!-- FB / OG Specific Tags --> */}
                    <meta property="fb:app_id" content="169380733077633" />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content="Garmin Connect" />
                    <meta
                        property="og:image"
                        content="http://static.garmincdn.com/com.garmin.connect/content/images/garmin-connect-fb3.gif"
                    />
                    {/* <!-- Twitter Tags --> */}
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:site" content="@GarminFitness" />
                    {/* <!-- TEALIUM TAGS --> */}
                    {/* moved to Consent component per ticket CA-91373 */}
                </Helmet>
                <div>
                    <Header toggleResponsiveMode={this.toggleResponsiveMode} />
                    <main className={background}>{renderChildren}</main>
                    {appendChildren}
                    {this.props.showFooter && renderFooter}
                </div>
            </>
        );
    }
}

Layout.defaultProps = {
    backgroundUrl: '',
    backgroundColor: '',
    responsiveBackgroundUrl: '',
    tabletBackgroundUrl: '',
    showFooter: true,
    noMinHeight: false,
    showActivityTracking: true
};

Layout.propTypes = {
    /** Subsequent children to be rendered right before the footer */
    appendChildren: PropTypes.object,
    /** Children to be rendered */
    children: PropTypes.object,
    /** The background image */
    backgroundUrl: PropTypes.string,
    /** The mobile background image */
    responsiveBackgroundUrl: PropTypes.string,
    /** The tablet background image */
    tabletBackgroundUrl: PropTypes.string,
    /** Option background color */
    backgroundColor: PropTypes.string,
    /** Hide / show footer */
    showFooter: PropTypes.bool,

    noMinHeight: PropTypes.bool,
    /** Show activity tracking in the footer */
    showActivityTracking: PropTypes.bool
};

export default injectSheet(styles)(Layout);
