import colors from './colors';

const themeColors = {
    
    // neutrals
    transparent:                        colors.transparent,
    white:                              colors.white,
    offWhite:                           colors.lightiu_surface_3,
    greyLight:                          colors.lightui_surface_4,
    greyMediumLight:                    colors.darkui_accent_3,
    greyMedium:                         colors.greyMedium, // 50% grey, but not an official Garmin UX color
    greyMediumDark:                     colors.darkui_accent_2,
    greyDark:                           colors.darkui_surface_3,
    black:                              colors.black,
    textColor:                          colors.darkui_surface_1,
    textColorWhite:                     colors.lightui_surface_2,
    
    // primary
    primary:                            colors.blue_primary,
    primaryButtonText:                  colors.white,
    primaryButtonBackground:            colors.blue_primary,
    primaryButtonBackgroundHover:       colors.blue_dark_1,
    primaryButtonBackgroundActive:      colors.blue_dark_2,
    primaryButtonBackgroundFocus:       colors.blue_primary,
    primaryButtonBoxShadow:             colors.blue_primary,
    primaryButtonLinkText:              colors.white,
    primaryButtonLinkTextHover: '',
    primaryButtonLinkTextActive: '',
    primaryButtonLinkTextFocus: '',
    primaryTextLinks:                   colors.blue_primary,
    primaryTextLinksHover: '',
    primaryTextLinksActive: '',
    primaryTextLinksFocus: '',
    primaryTextLinksVisited: '',
    PrimaryTextLinksDisabled: '',

    // secondary
    secondary:                          colors.darkui_accent_2,
    secondaryButtonText:                colors.white,
    secondaryButtonBackground:          colors.darkui_accent_2,
    secondaryButtonBackgroundHover:     colors.darkui_accent_1,
    secondaryButtonBackgroundActive:    colors.darkui_surface_4,
    secondaryButtonBackgroundFocus:     colors.darkui_accent_2,
    secondaryButtonLinkText:            colors.white,
    secondaryButtonLinkTextHover: '',
    secondaryButtonLinkTextActive: '',
    secondaryButtonLinkTextFocus: '',
    secondaryTextLinks: '',
    secondaryTextLinksHover: '',
    secondaryTextLinksActive: '',
    secondaryTextLinksFocus: '',
    secondaryTextLinksVisited: '',
    secondaryTextLinksDisabled: '',

    // danger
    danger: colors.red_primary,
    dangerButtonText:                   colors.white,
    dangerButtonBackground:             colors.red_primary,
    dangerButtonBackgroundHover:        colors.red_dark_1,
    dangerButtonBackgroundActive:       colors.red_dark_2,
    dangerButtonBackgroundFocus:        colors.red_primary,
    dangerButtonLinkText:               colors.white,
    dangerButtonLinkTextHover: '',
    dangerButtonLinkTextActive: '',
    dangerButtonLinkTextFocus: '',
    dangerTextLinks: '',
    dangerTextLinksHover: '',
    dangerTextLinksActive: '',
    dangerTextLinksFocus: '',
    dangerTextLinksVisited: '',
    dangerTextLinksDisabled: '',

    // reverse
    reverse: colors.black,
    reverseButtonText:                  colors.black,
    reverseButtonBackground:            colors.white,
    reverseButtonBackgroundHover:       colors.lightui_surface_2,
    reverseButtonBackgroundActive:      colors.lightui_surface_4,
    reverseButtonBackgroundFocus:       colors.white,
    reverseButtonLinkText:              colors.black,
    reverseButtonLinkTextHover: '',
    reverseButtonLinkTextActive: '',
    reverseButtonLinkTextFocus: '',
    reverseTextLinks: '',
    reverseTextLinksHover: '',
    reverseTextLinksActive: '',
    reverseTextLinksFocus: '',
    reverseTextLinksVisited: '',
    reverseTextLinksDisabled: '',

    // disabled                         // TODO - remove section (?!) as now handled by 0.5 opacity of primary/secondary/danger/reverse...
    /*
    disabled: '',
    disabledButtonText: '',
    disabledButtonBackground: '',
    disabledButtonBackgroundHover: '',
    disabledButtonBackgroundActive: '',
    disabledButtonBackgroundFocus: '',
    disabledButtonLinkText: '',
    disabledButtonLinkTextHover: '',
    disabledButtonLinkTextActive: '',
    disabledButtonLinkTextFocus: '',
    disabledTextLinks: '',
    disabledTextLinksHover: '',
    disabledTextLinksActive: '',
    disabledTextLinksFocus: '',
    disabledTextLinksVisited: '',
    disabledTextLinksDisabled: '',
    */

    // footer
    footerText:                         colors.darkui_accent_2,
    footerLinks:                        colors.darkui_accent_2,
    footerLinksHover:                   colors.darkui_accent_2,
    footerLinksActive:                  colors.darkui_accent_2,
    footerLinksFocus:                   colors.darkui_accent_2,
    footerLinksVisited:                 colors.darkui_accent_2,
    footerLinksDisabled: ''

    // alerts
    // forms
};

export default themeColors;
