import React from 'react';
import PropTypes from 'prop-types';
import i18next from '../config/i18next';

/**
 * Translate key to localized text. Similar to Localizer.
 * Sample:
 * <Translate ns='prelogin_pages'>{
        t => <div alt={t('fitness_at_your_fingers_content')} />
    }</Translate>
    if multiple ns are provided, t function requires ns:key format
 * <Translate ns={['prelogin_pages', 'device']}>{
        t => <img src={iphoneMobileMyDay} className={classes.iphoneMobileApp} 
                  title={t('device:device.support')} 
                  alt={t('prelogin_pages:fitness_at_your_fingers_content')} />
    }</Translate>
 *  <Translate ns='activity' content='key'/>
 *  <Translate ns='activity' content='key_with_place_holder' params={{0: 'param1', 1: 'param2'}}/>
 *
 * To pass HTML element in params, you'll need to provide a 'tag' and className as needed
 *  <Translate tag='div' className='' ns='activity' content='key_with_place_holder' params={{0: '<div>html element</div>'}}/>
 */
class Translate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            result: '',
            loaded: false
        };
        this.loaded = false;
    }

    componentDidMount() {
        if (this.props.content) {
            this.loadText();
        } else {
            i18next.loadNamespaces(this.props.ns, (err) => {
                if (err) {
                    console.log(err[0]);
                }
                this.setState({
                    loaded: true
                });
            });
        }
    }

    translate(key) {
        if (!this.state.loaded) {
            return;
        }

        if (Array.isArray(this.props.ns)) {
            // if ns is array, the key needs to be in ns:key format, no need to add ns
            return i18next.t(`${key}`);
        } else {
            return i18next.t(`${this.props.ns}:${key}`);
        }
    }

    loadText() {
        let key = this.props.children || this.props.content;

        if (!key) {
            throw Error('children or content must be provided');
        }

        i18next.loadNamespaces(this.props.ns, (err) => {
            if (err) {
                console.log(err[0]);
            }

            let text = i18next.t(`${this.props.ns}:${key}`, { skipInterpolation: true });
            if (typeof this.props.params === 'undefined') {
                this.setState({ result: text });
                return;
            }
            let result = this.parseText(text);
            this.setState({ result });
        });
    }

    parseText(text) {
        // support `text {0}wrapped text{/0} text`
        let parts = text.split(/({\w+?}|{\/\w+?})/g);

        let result = parts.reduce((acc, part, i) => {
            if (part.startsWith('{/') || (parts[i + 1] && parts[i + 1].startsWith('{/'))) {
                return acc;
            }

            if (!part.startsWith('{')) {
                // not a parameter, return text
                acc.push(part);
                return acc;
            }

            let prop = part.substring(1, part.length - 1);
            if (typeof this.props.params[prop] === 'undefined') {
                acc.push(part);
                return acc;
            }

            if (typeof this.props.params[prop] === 'object') {
                // React component
                // since we render array of components, React wants a unique key for each.
                let key = `${prop}_${Math.random()}`;
                if (parts[i + 2] && parts[i + 2].startsWith('{/')) {
                    let clone = React.cloneElement(this.props.params[prop], {
                        key: key,
                        children: [this.props.params[prop].props.children, parts[i + 1]]
                    });
                    acc.push(clone);
                } else {
                    acc.push(<React.Fragment key={key}>{this.props.params[prop]}</React.Fragment>);
                }
                return acc;
            }

            acc.push(this.props.params[prop]);
            return acc;
        }, []);
        return result;
    }

    /**
     * getDerivedStateFromProps() gets called just before render(), we need to update translations on the whole page when user selects another language
     * even though the props did not chnage.
     **/
    // static getDerivedStateFromProps(nextProps, prevState) {
    //     let key = nextProps.children || nextProps.content;
    //
    //     return {
    //         result: i18next.t(`${nextProps.ns}:${key}`, {
    //             ...nextProps.params,
    //             skipInterpolation: true
    //         })
    //     };
    // }

    renderWithContent() {
        let result = this.state.result;
        if (this.props.tag) {
            if (result instanceof Array) {
                result = result.join('');
            }
            return React.createElement(this.props.tag, {
                className: this.props.className,
                dangerouslySetInnerHTML: { __html: result }
            });
        }
        return result;
    }

    render() {
        if (this.props.content) {
            return this.renderWithContent();
        } else {
            return this.props.children(this.translate.bind(this));
        }
    }
}

Translate.propTypes = {
    /** Namespace | Property file name from translation project */
    ns: PropTypes.string.isRequired,
    /** Property key from translation project */
    content: PropTypes.string,
    /** Property key from translation project */
    children: PropTypes.oneOf([PropTypes.string, PropTypes.func]),
    /** Parameters in the text */
    params: PropTypes.object,
    /** Create HTML tag */
    tag: PropTypes.string,
    /** Provide className for the tag */
    className: PropTypes.string
};

export default Translate;
