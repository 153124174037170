import Cookie from 'js-cookie';
import { getSSOCookieDomain } from './ssoUtil';

export const isUserSignedIn = () => {
    if (typeof window !== `undefined`) {
        const ssoDomain = getSSOCookieDomain(window.location.href);

        /*
         *   Connect Modern will set GARMIN-SSO to 1 to determine if the user is signed in.
         */
        if (Cookie.get('GARMIN-SSO', { domain: ssoDomain })) {
            return true;
        }
        return false;
    }
    return false;
};
